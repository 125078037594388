<template>
  <div>
    <div class="mb-1">
      <label for="photo" class="font-weight-bolder">Photo :</label>
      <input class="form-control" type="file" @change="uploadImageSuccess" />
    </div>
<!-- v-if="photoUrl !== '' || isEdit" -->
    <div class="text-center mb-1" v-if="photoUrl != ''">
      <div class="mb-1">
        <img :src="photoUrl ? photoUrl : editPhotoObj.name" class="rounded shadow-sm" height="200px;" alt="photo" />
      </div>
      <b-button variant="danger" @click="removePhoto">
        <feather-icon icon="TrashIcon" />
      </b-button>
    </div>
    <p class="text-center text-danger" v-if="loading">loading . . .</p>
  </div>
</template>
    
  <script>
import store from "@/store";
import { ref , onUnmounted } from "@vue/composition-api";
import { BCard, BButton, BSpinner, BRow, BCol } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BButton,
    BSpinner,
    BRow,
    BCol,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    // editPhotoUrl: {
    //   type: String,
    //   default: '',
    // }
    editPhotoObj: {
      type: Object,
      default: null,
    }
  },
  setup(props, { emit }) {
    onUnmounted(() => {
      photoId.value = "";
      photoUrl.value = "";
    })
    const photoId = ref(props.editPhotoObj?.id ? props.editPhotoObj.id : "");
    const photoUrl = ref(props.editPhotoObj?.name ? props.editPhotoObj.name : "");
    const loading = ref(false);
    const uploadImageSuccess = (e) => {
      loading.value = true;
      // emit("input", e.target.files[0]);
      // let reader = new FileReader();
      // reader.readAsDataURL(e.target.files[0]);
      // reader.onload = (event) => {
      //   photoUrl.value = event.target.result;
      // };
      const file = e.target.files[0];
      const formData = new FormData();
      formData.set("name", file);
      store
        .dispatch("app-mypages/postDatas", { path: "photo", payload: formData })
        .then((response) => {
          photoUrl.value = response.data.data.name;
          console.log('photo url - ' , photoUrl.value)
          photoId.value = response.data.data.id;
          emit("getPhotoId", photoId.value);
          loading.value = false;
        });
    };

    const removePhoto = () => {
      loading.value = true;
      console.log(photoId.value)
      store
        .dispatch("app-mypages/delData", {
          path: "photo",
          id: props.editPhotoObj.id,
        })
        .then((response) => {
          props.isEdit = false;
          photoId.value = "";
          photoUrl.value = "";
          props.editPhotoObj = {};
          loading.value = false;
        });
    };

    return {
      uploadImageSuccess,
      removePhoto,
      photoUrl,
      photoId,
      loading
    };
  },

};
</script>
    
